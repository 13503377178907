
@font-face {
    font-family: 'TitleFont';
    src: url('./AlimamaFangYuanTiVF-Thin.ttf') format('BoldSquare');
    /* 可选：添加其他字体样式 */
    font-weight: normal;
    font-style: normal;
  }

.home-banner{
    width: 100%;
    height: calc(100vh - 104px);
    background-image: url('../assets/page.png');
    background-position: center;
    background-size: cover;
    .content{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 50%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.3); 
        padding-left: 80px;
        color: #fff;
        .title{
            font-size: 42px;
        }
        .item{
            padding-top: 20px;
            font-size:18px;
        }
    }

}
.team-banner{
    width: 100%;
    height: calc(100vh - 104px);
    display: flex;
    background-position: center;
    background-size: cover;
    background-image: url('../assets/team.png');

    .content{
        width: 60%;
        background: #fff;
        display: flex;
        padding: 80px 20px;
        flex-wrap: wrap; 
        align-items: stretch;
        align-content: flex-start;
        .vertical-line {
            position: inherit;
            margin:20px;
            padding-top: 20px;
            width: 12px;
            height: 16vh; 
            background-color: rgba(91, 155, 213)
        }
        .title {
            margin: 20px 0;
            line-height: 8vh;
            width: calc(90% - 40px);
            .title-chinese{
                font-size: 32px;
            }
            .title-english{
                font-size: 48px;
                font-weight: 800;
            }
        }
        .text{
            margin:  60px 20px  40px;
            width: calc(90% - 40px);
            font-size: 26px;
        }

        .data{
            line-height: 8vh;
            width: calc(90% - 40px);
            display: flex;
            .vertical-line {
                position: inherit;
                margin:20px;
                padding-top: 20px;
                width: 1px !important;
                height: 16vh; 
                background-color: #c2babaf0; 
            }
            .company-data{
                display: flex;
                flex-flow: row nowrap;
                margin: 20px;
            }
            .data-text{
                flex: 0 1 auto;
                margin: 20px;
                max-width: 70%;
                .num{
                    font-size: 40px;
                }
            }
           
        }
    }
    

}

.product-banner{
    width: 100%;
    height: calc(100vh - 104px);
    background-color: rgba(255, 255, 255);
    overflow: hidden;
    display: flex;

     .content {
        float: left;
        width: 60%; 
        .main{
            display: flex;;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            .item{
                padding: 20px 10px;
                font-size: 45px;
                font-weight: 600;
            }
            
        }
        
        .introduce{
            flex-flow: row wrap;
            width: 700px;
            height: 100%;
            margin: 0 100px;
            padding: 128px 0 0 68px;
          
            .introduce-item{
                align-items: center;
                border: 2px solid #4472C4;
                aspect-ratio: 1;
                width: 175px;
                max-width: 380px;
                margin: 5px 10px;
                border-radius: 15%;
                text-align: center;
                padding-top: 10px;
            }

            .title,
            .note{
                margin: 5px 0;
            }
            .image{
                width: 50%;
                margin: 0 auto;
                img {
                    width: 100%;
                }
            }

            .product-logo {
                width: 70px;
                top: 8px;
                left: 14px;
                position: relative;
            }

        }

        .introduce-img {
            display: flex;
            margin-top: 48px;
            justify-content: space-between;
            margin-right: 60px;
        }

        .introduce-title {
            font-family: 'TitleFont', sans-serif;
            font-size: 48px;
            font-weight: 550;
            margin-bottom: 48px;
            padding-left: 130px;
        }

        .title-desc {
            position: relative;
            bottom: 9px;
            left: 20px;
        }

        .introduce-desc {
            font-size: 22px;
            margin-top: 18px;
        }
    }
}
.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.product-introduce {
    width: 50%;
    padding: 68px;
    display: flex;
    justify-content: center;
}

.product-phone {
    width: 50%;
    padding: 68px;
    display: flex;
    justify-content: center;
}