* {
    margin: 0;
    padding: 0;
}

body {
    background-image: linear-gradient(180deg, #ECF3FE 0%, #F4F6FE 11%, #F4F6FE 100%);
}

#root {

    .ant-layout {


        .ant-layout-header {
            background: #DEE5FA;
            box-shadow: 0px 1px 8px 0px rgba(76, 70, 126, 0.1);
            display: flex;
            align-items: center;

            .logo {
                width: 110px;
                height: 30px;
            }
        }

        .ant-layout-content {
            // img {
            //     width: 100%;
            // }
            .body {
                margin-top: 50px;
            }

            .company-text {
                height: 40px;
                background-color: rgba(0, 0, 0, 0.3);
                margin-bottom: -40px;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #FFFFFF;
                font-weight: 400;
                text-align: center;
                line-height: 40px;
                position: relative;
                bottom: 3px;
                .report{
                    margin-left: 8px;
                    color: #1D43FE;
                }
            }

            .menu {
                height: 68px;
                padding: 8px 0;
                background-color: rgba(53, 98, 134,0.85) ;
            }

            .menu-title {
                float: left;
                position: relative;
                top: 8px;
                left: 24px;
                font-size: 24px;
                color: #FFFFFF;
            }

            .menu-navigate {
                float: right;
                display: flex;
                line-height: 50px;
            }

            .menu-navigate-item {
                margin-right: 120px;
                font-size: 18px;
                position: relative;
                cursor: pointer;
                color: #fff;
            }

            .footer-text {
                display: inline-block;
                margin-left: 32px;
            }

            .footer-text-link {
                display: inline-block;
                margin-left: 32px;
                cursor: pointer;
            }

            .footer-text-link:hover {
                color:#222
            }


        }

    }
}